<template>
  <div>
    <div class="row">
      <div v-if="loadingCard" class="col text-center">
        <i class="fad fa-spin fa-spinner fa-3x"></i>
      </div>
      <div class="col-md-6 mx-auto" v-if="order.currency_id !== 1">
        <div class="card border-0">
          <div class="card-body text-center">
            <h1><i class="fa fa-exclamation-circle fa-3x text-primary"></i></h1>
            <p>Unfortunately, card payment is not available for this order.</p>
          </div>
        </div>
      </div>

      <div v-else class="col" :class="loadingCard ? 'd-none' : ''">
        <div class="card shadow-sm">
          <div class="card-body" :class="order && order.clinic.charges_enabled ? '' : 'd-none'">
            <form role="form">
              <!-- Cardholder's Name -->
              <div class="row mb-4">
                <div class="col mx-auto">
                  <label for="name">Cardholder's Name</label>
                  <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-model="name"
                  />
                </div>
              </div>

              <!-- Card Details -->
              <div class="row mb-4">
                <div class="col mx-auto">
                  <label for="name">Card Details</label>
                  <div id="payment-card-element"></div>
                </div>
              </div>

              <hr />

              <!-- Payment Button -->
              <div class="row mb-0">
                <div class="col mx-auto text-right">
                  <button
                      type="submit"
                      class="btn btn-primary"
                      @click.prevent="processPayment"
                      :disabled="busy"
                  >
                    <span v-if="busy">
                      <i class="fa fa-btn fa-spinner fa-spin"></i> Processing
                      Payment
                    </span>

                    <span v-else>Make Card Payment</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ["clinic", "order", "instalment", "paymentType"],
  data() {
    return {
      name: "",
      busy: false,
      loadingCard: true,
      cardElement: null,
      stripe: null,
      paymentIntent: null,
    };
  },
  methods: {
    fetchOrder() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/client/orders/" + this.$route.params.id).then(({ data }) => {
        this.order = data;
      });
    },
    fetchInstalmentPaymentIntent() {
      return this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/orders/instalments/" +
            this.instalment.id +
            "/payment-intent"
        )
        .then(({ data }) => {
          this.paymentIntent = data;
        });
    },
    fetchRemainingAmountPaymentIntent() {
      return this.$axios
        .get(
            process.env.VUE_APP_API_URL + "/client/orders/" + this.order.id + "/remaining-amount-payment-intent"
        )
        .then(({ data }) => {
          this.paymentIntent = data;
        });
    },
    initStripe() {
      this.loadingCard = true;
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      this.cardElement = this.createCardElement("#payment-card-element");
      this.loadingCard = false;
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
              'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);
      return card;
    },
    processPayment() {
      this.busy = true;
      this.stripe
        .confirmCardPayment(this.paymentIntent.client_secret, {
          payment_method: {
            card: this.cardElement,
          },
        })
        .then((result) => {
          if (result.error) {
            console.log(result.error);
            this.$EventBus.$emit("alert", {
              color: "danger",
              message: result.error.message,
            });
            self.hasCardErrors = true;
            this.busy = false;
            return;
          }
          this.$EventBus.$emit("alert", { message: "Payment Successful! Thank you." });
          this.$emit("success");
          this.busy = false;
        });
    },
  },
  mounted() {
    if (this.instalment) {
      this.fetchInstalmentPaymentIntent().then(() => {
        this.initStripe();
      });
    } else {
      this.fetchRemainingAmountPaymentIntent().then(() => {
        this.initStripe();
      });
    }
  },
  filters: {
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal === "remaining") {
        this.loadingCard = true;
        this.fetchRemainingAmountPaymentIntent().then(() => {
          this.loadingCard = false;
        });
      } else if (newVal === "instalment") {
        this.loadingCard = true;
        this.fetchInstalmentPaymentIntent().then(() => {
          this.loadingCard = false;
        });
      }
    },
  },
};
</script>

<style>
</style>
