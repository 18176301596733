<template>
  <div class="container">
    <loading v-if="loadingPaymentMethods" />
    <div v-else class="row">
      <div class="col">

        <div v-if="order.status !== 'paid'">
          <div v-if="paymentsAllowed">
            <!-- adyen drop in payments -->
            <div ref="dropincontainer"></div>

            <div class="ml-auto mt-2 w-100 text-right">
              <a href="https://swandoola.com/terms" class="small">
                Terms & Conditions
              </a>
              <a href="https://swandoola.com/privacy" class="small ml-2">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import Loading from "@/views/components/loaders/Loading";

export default {
  components: {Loading},
  props: ["clinic", "order", "instalment", "paymentType"],
  data() {
    return {
      loadingPaymentMethods: true,
      paymentMethods: [],
      paymentError: '',
      paymentBusy: false,
    };
  },
  methods: {
    fetchPaymentMethods() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/client/adyen/payment-methods")
          .then(({ data }) => {
            this.paymentMethods = data;
            this.loadingPaymentMethods = false;
          });
    },
    initAdyenDropinComponent() {
      const checkout = new AdyenCheckout(this.adyenPaymentConfiguration);
      const dropin = checkout.create('dropin').mount(this.$refs.dropincontainer);
    },
    makePayment(data) {
      //Handle 'then' in adyenPaymentConfiguration computed property methods
      if (!this.paymentBusy){
        this.paymentBusy = true;

        return this.$axios.post(process.env.VUE_APP_API_URL + "/client/orders/" + this.$route.params.id + "/make-payment",
            {
              paymentState: data,
              paymentType: this.paymentType,
              instalment: this.nextInstalment
            }
          );
      } else {
        return null;
      }
    },
    showFinalResult(data, dropin) {
      const result = data.payment;
      switch (result.resultCode) {
        case 'Authorised':
          dropin.setStatus('success', { message: 'Payment successful!' });
          break;
        case 'Pending':
          dropin.setStatus('success', { message: 'We have received your order and are awaiting payment to be completed. You will receive final result of the payment shortly.' });
          break;
        case 'Received':
          dropin.setStatus('success', { message: 'We have received your order and are awaiting payment to be completed. You will receive final result of the payment shortly.' });
          break;
        case 'Refused':
          dropin.setStatus('error', { message: result.refusalReason });
          setTimeout(function () {
            dropin.setStatus('ready');
          }, 3000);
          break;
        case 'Error':
          dropin.setStatus('error', { message: result.refusalReason });
          setTimeout(function () {
            dropin.setStatus('ready');
          }, 3000);
          break;
      }
      console.log(data)
    },
  },
  mounted() {
    this.fetchPaymentMethods().then( () => {
      this.initAdyenDropinComponent();
    });
  },
  filters: {
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  computed: {
    nextInstalment() {
      return this.order.instalments.filter((instalment) => {
        return instalment.status === "not_paid";
      })[0] ?? null
    },
    paymentsAllowed() {
      return this.$store.user.clinic.payments_allowed
    },
    adyenPaymentConfiguration() {
      return {
        paymentMethodsResponse: this.paymentMethods, // The `/paymentMethods` response from the server.
        clientKey: process.env.VUE_APP_ADYEN_CLIENT_ORIGIN_KEY,
        locale: "en-GB",
        environment: process.env.VUE_APP_ADYEN_ENVIRONMENT.toLowerCase(),
        onSubmit: (state, dropin) => {
          // Your function calling your server to make the `/payments` request
          this.makePayment(state.data)
              .then(response => {
                const responseData = response.data;
                console.log("response");
                console.log(responseData);
                if (responseData.action) {
                  // Drop-in handles the action object from the /payments response
                  dropin.handleAction(responseData.action);
                } else {
                  // Your function to show responseData final result to the shopper
                  this.showFinalResult(responseData, dropin);
                }
                this.paymentBusy = false;
              })
              .catch(error => {
                throw Error(error);
              });
        },
        paymentMethodsConfiguration: {
          card: { // Example optional configuration for Cards
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false, // Change this to true to hide the CVC field for stored cards
            name: 'Credit or debit card'
          }
        }
      };
    }
  },
  watch: {

  },
};
</script>

<style>
</style>
