<template>
  <div class="container">
    <loading v-if="loading" />
    <div v-else class="row">
      <div class="col-md-6 mx-auto">
        <h5 class="mb-3">Secure Card Payment for Order #{{ this.$route.params.id }}</h5>

        <div class="card border-0 shadow-sm mb-2" v-if="order">
          <div class="card-body text-center">
            <div class="card bg-light">
              <div class="card-body">
                <h5 class="font-weight-bold text-primary mb-3">
                  Total: {{ order.price_display }}
                </h5>
                <h5 class="font-weight-bold text-primary mb-3">
                  Amount Outstanding: {{order.currency.symbol}}{{
                    order.amount_outstanding | currencyFormat
                  }}
                </h5>

                <button class="btn btn-outline-primary"
                        v-if="order.clinic.allow_client_invoice_download"
                        @click="viewInvoice">
                  <i class="fad fa-file-invoice mr-1"></i>
                  Download Invoice
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order.status !== 'paid'">
          <div class="card shadow-sm mb-2" v-if="order && order.instalments.length > 1">
            <div class="card-body">
              <p class="alert alert-primary">
                <i class="fad fa-info-circle mr-1"></i>
                This order allows payment via instalments. You can choose to pay
                either a single instalment or to pay the full order amount now.
              </p>
              <div class="row">
                <div class="col">
                  <div class="form-check" v-if="nextInstalment">
                    <input
                        class="form-check-input"
                        name="paymentType"
                        type="radio"
                        v-model="paymentType"
                        value="instalment"
                    />
                    <label class="form-check-label">
                      Pay instalment: {{order.currency.symbol}}{{nextInstalment.total | currencyFormat }}
                    </label>
                  </div>
                  <div class="form-check" v-if="nextInstalment">
                    <input
                        class="form-check-input"
                        name="paymentType"
                        type="radio"
                        v-model="paymentType"
                        value="remaining"
                    />
                    <label class="form-check-label">
                      Pay total outstanding amount: {{order.currency.symbol}}{{order.amount_outstanding | currencyFormat }}
                    </label>
                  </div>
                  <p class="mt-2 mb-0" v-if="nextInstalment">
                    Amount to pay now:
                    <b v-if="paymentType === 'instalment'">
                      {{order.currency.symbol}}{{ this.nextInstalment.total | currencyFormat }}
                    </b>
                    <b v-else>
                      {{order.currency.symbol}}{{ this.order.amount_outstanding | currencyFormat }}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow-sm">
            <div class="card-body" v-if="paymentsAllowed">
              <adyen-payment v-if="clinic.has_adyen_integration"
                             :clinic="clinic"
                             :order="order"
                             :instalment="nextInstalment"
                             :payment-type="paymentType"
                             @success="onSuccess" />

              <stripe-payment v-else-if="clinic.has_stripe_integration"
                              :clinic="clinic"
                              :order="order"
                              :instalment="nextInstalment"
                              :payment-type="paymentType"
                              @success="onSuccess" />

            </div>
            <div class="card-body text-center" v-else>
              <p class="mb-0">
                <i class="fa fa-info-circle text-info mr-1"></i>
                Please refer to your appointment confirmation email for further details or refer to your invoice for payment instructions.
              </p>
            </div>
          </div>
        </div>

      </div>

      <div v-if="order.status === 'cancelled'" class="col text-center">
        <p class="alert alert-danger">Order Cancelled.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loaders/Loading";
import AdyenPayment from "./AdyenPayment";
import StripePayment from "./StripePayment";

export default {
  components: {StripePayment, AdyenPayment, Loading},
  props: [],
  data() {
    return {
      loading: true,
      order: null,
      clinic: null,
      paymentType: "instalment",
    };
  },
  methods: {
    fetchOrder() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/client/orders/" + this.$route.params.id).then(({ data }) => {
        this.order = data;
        this.clinic = this.order.clinic;
        this.loading = false;
      });
    },
    viewInvoice() {
      this.$router.push("/orders/" + this.$route.params.id + "/invoice");
    },
    onSuccess() {
      const vm = this;
      setTimeout(() => {
        vm.$router.push("/orders");
      }, 2000);
    }
  },
  filters: {
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  mounted() {
    this.fetchOrder();
  },
  computed: {
    unpaidInstalments() {
      return this.order.instalments.filter((instalment) => {
        return instalment.status === "not_paid";
      });
    },
    nextInstalment() {
      return this.unpaidInstalments[0] ?? null
    },
    paymentsAllowed() {
      if (this.clinic.has_adyen_integration) {
        return this.clinic.payments_allowed
      } else if (this.clinic.has_stripe_integration) {
        return this.clinic.charges_enabled
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>
